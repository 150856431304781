@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Parisienne");

main {
  width: 50%;
  margin: 0 auto 5rem;
  text-align: center;
  max-width: 1337px;
  font-family: "Noto Sans JP", sans-serif;

  @media (width <= 1200px) {
    width: 60%;
  }

  @media (width <= 900px) {
    width: 70%;
  }

  @media (width <= 767px) {
    width: 80%;
  }

  ul {
    padding: 0;
    li {
      list-style-type: none;
    }
  }

  h1 {
    font-size: 70px;
    font-family: "Parisienne", cursive;
    @media (width <= 767px) {
      font-size: 60px;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: initial;
  }

  p,
  a,
  label {
    font-size: 18px;
  }
}
