.onskeliste {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &_liste {
    li {
      border-radius: 0.5rem;
      border: 1px solid rgba(7, 26, 54, 0.21);
      padding: 1rem;
      width: 90%;
      margin: 0 auto;
    }
  }
}
