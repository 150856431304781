.personer {
  display: flex;
  flex-direction: column;

  &_bolk {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
    row-gap: 2rem;

    @media (width <= 767px) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      gap: 0;
    }
  }

  .image {
    width: 100%;
    max-width: 20rem;
    height: auto;
    border-radius: 100%;
  }
}
