.mainpage_informasjon {
  margin: 0 auto;
}

.mainpage_image {
  max-width: 100rem;
  height: auto;
  width: 100%;
  margin-top: 3rem;
}

.temp {
  margin: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    max-width: 37rem;
    height: auto;
  }
}
