.divider {
  display: table;
  font-size: 24px;
  text-align: center;
  align-items: center;
  width: 90%;
  margin: 20px auto;

  h2 {
    font-size: 28px;
    margin: 0;
    text-decoration: none;
  }
}
.divider span {
  display: table-cell;
  position: relative;
}
.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 22px; /* adjust vertical align */
  -moz-background-size: 100% 2px;
  background-size: 100% 2px;
  background-position:
    0 0,
    0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(transparent),
    to(#000)
  );
  background-image: -webkit-linear-gradient(180deg, transparent, #000);
  background-image: -moz-linear-gradient(180deg, transparent, #000);
  background-image: -o-linear-gradient(180deg, transparent, #000);
  background-image: linear-gradient(90deg, transparent, #000);
}
.divider span:nth-child(2) {
  color: #000;
  padding: 0px 5px;
  width: auto;
  white-space: nowrap;
}
.divider span:last-child {
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#000),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(180deg, #000, transparent);
  background-image: -moz-linear-gradient(180deg, #000, transparent);
  background-image: -o-linear-gradient(180deg, #000, transparent);
  background-image: linear-gradient(90deg, #000, transparent);
}
