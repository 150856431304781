.brudeparet {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  &_bilde {
    width: 100%;
    height: auto;
    max-width: 40rem;
    &_main {
      width: 100%;
      height: auto;
      max-width: 100rem;
    }
  }

  .star_wars {
    max-width: 30rem;
  }
}
