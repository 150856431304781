.kart {
  height: 100%;
  width: 100%;
  border: 0;
}

.sporten_img {
  width: 100%;
  height: auto;
  max-width: 40rem;
  margin-bottom: 1rem;
}

.sporten_kart {
  width: 100%;
  max-width: 40rem;
  height: auto;
  min-height: 20rem;
}

.informasjon_h3 {
  font-weight: bolder;
}

.informasjon_bolk {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.informasjon_img {
  width: 40%;
  height: auto;
  max-width: 15rem;
}
