.header {
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    margin: 3rem 0 0;
  }

  &_img {
    width: 100%;
    height: auto;
    max-width: 25rem;
  }
}
