@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap");

.navbar_wrapper {
  box-shadow: 1px 5px 20px 0 rgb(192, 192, 192);
  position: absolute;
  width: 100%;
  background-color: white;
  top: 0;
}

.hamburger {
  display: none;
  @media (max-width: 767px) {
    width: 2rem;
    height: 0;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
  }
}

.liste_wrapper {
  margin-left: 0.5rem;
  margin-top: 2rem;

  .navbar_hamburger {
    @media (width <= 767px) {
      display: inline !important;
    }
  }
}

.navbar {
  list-style-type: none;
  margin: 1rem;
  gap: 2.5rem;
  max-width: 40rem;
  padding-inline-start: 0;
  display: flex;
  justify-content: flex-start;
  font-family: "Noto Sans JP", sans-serif;
  text-align: start;

  @media (width <= 600px) {
    margin: 0.5rem;
    gap: 0.5rem;
  }
  @media (width <= 767px) {
    display: none;
  }

  .navbar_element_aktiv {
    text-decoration: underline;
  }

  &_element {
    a {
      text-decoration: none;
      color: black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
